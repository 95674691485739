import React, { Fragment } from "react";

const OurService = (props) => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <section className="position-relative bg__black" style={{ paddingTop: "123px" }}>
          <div  style={{paddingBottom: "40px"}} className="container">
            <div className="row mb-5">
              <div className="col-md-6 my-auto order-12 order-md-1">
                <div className="mb-4 d-inline-block position-relative">
                  <h2 className="bold font__size--40 text__40-1024 text__40-md text__40-mm color__white mb-0 syne position-relative z-2">
                    Steering towards Success with Our Expertise
                  </h2>
                  <img
                    src="./../images/Graphic (1).png"
                    className="images__right-head"
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 mb-5 my-md-auto text-md-right text-center order-md-12 order-1"></div>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="wrapper__service-wrap">
                  <img
                    src="./../images/dg/ic (4).svg"
                    className="color__gray-1"
                    alt=""
                  />
                  <h4 style={{paddingTop: "20px"}} className="bold font__size--24 text__24-1024 color__white syne">
                    Software Development
                  </h4>
                  <p className="normal font__size--16 text__16-1024 color__white">
                    Tailor-made software solutions to address your maritime
                    business needs, driving efficiency and enhancing operations.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="wrapper__service-wrap">
                  <img src="./../images/dg/ic (5).svg" className="" alt="" />
                  <h4 style={{paddingTop: "20px"}} className="bold font__size--24 text__24-1024 color__white syne">
                    Data Management Solutions
                  </h4>
                  <p className="normal font__size--16 text__16-1024 color__white">
                    We help our partners harness the power of their data with
                    solutions that facilitate data collection, storage,
                    analysis, and reporting, turning raw data into actionable
                    insights.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="wrapper__service-wrap">
                  <img src="./../images/dg/ic (6).svg" className="" alt="" />
                  <h4 style={{paddingTop: "20px"}} className="bold font__size--24 text__24-1024 color__white syne">
                    Mobile App Development
                  </h4>
                  <p className="normal font__size--16 text__16-1024 color__white">
                    Intuitive and feature-rich mobile applications for maritime
                    professionals, providing access to critical information on
                    the go.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="wrapper__service-wrap">
                  <img
                    src="./../images/dg/ic (7).svg"
                    className="color__gray-1"
                    alt=""
                  />
                  <h4 style={{paddingTop: "20px"}} className="bold font__size--24 text__24-1024 color__white syne">
                    Cloud Solutions and Infrastructure
                  </h4>
                  <p className="normal font__size--16 text__16-1024 color__white">
                    Robust cloud solutions tailored to the maritime industry,
                    ensuring seamless data management, collaboration, and system
                    accessibility.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="wrapper__service-wrap">
                  <img src="./../images/dg/ic (8).svg" className="" alt="" />
                  <h4 style={{paddingTop: "20px"}} className="bold font__size--24 text__24-1024 color__white syne">
                    IoT Integration and Automation
                  </h4>
                  <p className="normal font__size--16 text__16-1024 color__white">
                    Connect devices and automate processes in the maritime
                    industry, enabling real-time monitoring, predictive
                    maintenance, and improved efficiency.
                  </p>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 mb-4">
                <div className="wrapper__service-wrap">
                  <img src="./../images/dg/ic (9).svg" className="" alt="" />
                  <h4 style={{paddingTop: "20px"}} className="bold font__size--24 text__24-1024 color__white syne">
                    Cybersecurity and Data Protection
                  </h4>
                  <p className="normal font__size--16 text__16-1024 color__white">
                    Comprehensive cybersecurity strategies to protect your
                    maritime data and systems, ensuring compliance and
                    safeguarding against cyber threats.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default OurService;
