import React from "react";
import { useRef } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const AboutUs = () => {
  return (
    <div className="overflow-hidden">
      <section
        id="how-we-work"
        style={{ paddingBottom: "67px" }}
        className="position-relative bg__white"
      >
        <div className="container">
          {false && <div className="mb-4 d-inline-block position-relative">
            <h2 className="bold font__size--24 text__40-1024 text__40-md text__40-mm mb-0 syne position-relative z-2 color__gray-1">
              Smooth Sailing
            </h2>
            <img
              src="./../images/fhfghg.png"
              className="images__line-text"
              alt=""
            />
          </div>}
          <div className="row mb-5">
            <div className="col-md-6 mb-4 my-md-auto">
              <h2 className="bold font__size--48 text__48-1024 text__48-md text__48-sm syne mb-0 color__gray-1">
                Charting Your Path to
                <span className="position-relative">
                  &nbsp;Success&nbsp;&nbsp;
                  <img
                    src="./../images/Graphic.png"
                    className="images__line-head"
                    alt=""
                  />
                </span>
              </h2>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-md-6 my-auto order-12 order-md-1">
              <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                <div>
                  <img src="./../images/dg/icon (1).png" alt="" />
                </div>
                <div style={{ flex: 1 }}>
                  <div className="mb-4 d-inline-block position-relative">
                    <h2
                      style={{ lineHeight: "160%" }}
                      className="bold font__size--32 text__40-1024 text__40-md text__40-mm mb-0 syne position-relative z-2  color__gray-1"
                    >
                      Deep Maritime Knowledge
                    </h2>
                  </div>

                  <p className="normal font__size--16 text__20-1024 mb-4 color__gray-2">
                    We're not just tech experts; we understand the maritime
                    industry's unique challenges and needs. This deep industry
                    knowledge informs every solution we develop.
                  </p>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                <div>
                  <img src="./../images/dg/icon (2).png" alt="" />
                </div>
                <div style={{ flex: 1 }}>
                  <div className="mb-4 d-inline-block position-relative">
                    <h2
                      style={{ lineHeight: "160%" }}
                      className="bold font__size--32 text__40-1024 text__40-md color__gray-1 text__40-mm mb-0 syne position-relative z-2"
                    >
                      Agile Development Approach
                    </h2>
                  </div>

                  <p className="normal font__size--16 text__20-1024 mb-4 color__gray-2">
                    We use agile development methodologies to ensure our
                    solutions are adaptable and responsive to changing
                    requirements. This approach allows us to deliver
                    high-quality software quickly and efficiently.
                  </p>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                <div>
                  <img src="./../images/dg/icon (3).png" alt="" />
                </div>
                <div style={{ flex: 1 }}>
                  <div className="mb-4 d-inline-block position-relative">
                    <h2
                      style={{ lineHeight: "160%" }}
                      className="bold font__size--32 text__40-1024 text__40-md text__40-mm mb-0 syne position-relative z-2 color__gray-1"
                    >
                      User-Centric Design
                    </h2>
                  </div>

                  <p className="normal font__size--16 text__20-1024 mb-4 color__gray-2">
                    We believe that the best solutions are designed with the
                    end-user in mind. Our design process prioritizes usability
                    and intuitiveness, ensuring our software is easy to use and
                    meets the needs of our partners.
                  </p>
                </div>
              </div>

              <div style={{ display: "flex", flexDirection: "row", gap: 20 }}>
                <div>
                  <img src="./../images/dg/icon (4).png" alt="" />
                </div>
                <div style={{ flex: 1 }}>
                  <div className="mb-4 d-inline-block position-relative">
                    <h2
                      style={{ lineHeight: "160%" }}
                      className="bold font__size--32 text__40-1024 text__40-md text__40-mm mb-0 syne position-relative z-2 color__gray-1"
                    >
                      Comprehensive Support
                    </h2>
                  </div>

                  <p className="normal font__size--16 text__20-1024 mb-4 color__gray-2">
                    Our commitment to our partners doesn't end when we deliver a
                    solution. We provide end-to-end support, from initial
                    consultation and development to implementation and ongoing
                    maintenance.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-5 my-md-auto text-md-right text-center order-md-12 order-1">
              <div>
                <img src="./../images/dg/decor (1).png" alt="" />
                <img src="./../images/dg/sc_board.png" alt="" />
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "#03C49B",
                    bottom: 0,
                    left: -81,
                    width: 475,
                    height: 70,
                    opacity: 0.8,
                  }}
                >
                  &nbsp;
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ marginTop: "118px", marginBottom: 0 }}
            className="row mb-5"
          >
            <a
              style={{ whiteSpace: "nowrap" }}
              className="medium font__size--16 text__16-1024 btn btn__green shadow color__white"
              rel="noopener"
              href="#how-to-contact"
            >
              Set Sail with Us
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUs;
