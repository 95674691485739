import React, { useState, Fragment, useRef } from "react";

const Testimonial = (props) => {
  return (
    <Fragment>
      <section style={{paddingTop: "120px"}} className="bg__black">
        <div className="container">
          <div className="text-center mb-4 mb-xl-5">
            <h2 className="bold font__size--48 text__48-1024 text__48-md text__48-sm color__white syne">
              Partners We've Empowered
            </h2>
            <p className="normal font__size--18 text__18-1024 color__white opacity__8">
              Discover the success stories of our partners and learn how we've{" "}
              <br className="d-none d-md-block" /> navigated them towards new
              horizons.
            </p>
          </div>

          <div style={{gap:114}} className="wrapper__logo-brand mb-5 mb-xl-0">
            <img style={{opacity:0.5}} src="./../images/dg/brand_queensway.png" alt="" />
            <img style={{opacity:0.5}} src="./../images/dg/brand_vsltec.png" alt="" />
            <img style={{opacity:0.5}} src="./../images/dg/brand_harborlab.png" alt="" />
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Testimonial;
