import React, { Component, Fragment } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const Navbar = (props) => {
  const [toogleMenu, setToogleMenu] = useState(false);

  return (
    <Fragment >
      <div
        className={
          "wrapper__side-nav-mobile d-flex d-xl-none " +
          (toogleMenu ? "active" : "")
        }
      >
        <div className="d-flex flex-wrap w-100">
          <div className="w-100">
            <div className="d-flex align-items-center justify-content-between mb-4 position-relative">
              <img
                src="./../images/Close.png"
                onClick={() => setToogleMenu(!toogleMenu)}
                className="pointer"
                alt=""
              />
              <h5 className="mb-0 font__size--20 color__white title syne">
                Menu
              </h5>
            </div>
            <div className="menu text-center">
              <a
                onClick={() => setToogleMenu(!toogleMenu)}
                style={{ whiteSpace: "nowrap" }}
                className="bold list font__size--28 syne color__white"
                rel="noopener"
                href="#top"
              >
                Home
              </a>
              <a
                onClick={() => setToogleMenu(!toogleMenu)}
                style={{ whiteSpace: "nowrap" }}
                className="bold list font__size--28 syne color__white"
                rel="noopener"
                href="#who-we-are"
              >
                Who We Are
              </a>
              <a
                onClick={() => setToogleMenu(!toogleMenu)}
                style={{ whiteSpace: "nowrap" }}
                className="bold list font__size--28 syne color__white"
                rel="noopener"
                href="#what-we-do"
              >
                What We Do
              </a>
              <a
                onClick={() => setToogleMenu(!toogleMenu)}
                style={{ whiteSpace: "nowrap" }}
                className="bold list font__size--28 syne color__white"
                rel="noopener"
                href="#how-we-work"
              >
                How We Work
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bg__wrap-menu d-xl-none" onClick={() => setToogleMenu(!toogleMenu)}></div> */}
      <nav
        id="navbar"
        className="navbar navbar-expand-xl bg__black wrapper__navbar position-fixed z-2"
        data-aos="fade-down"
        data-aos-duration="1000"
        data-aos-delay="300"
      >
        <div className="container position-relative">
          <a
            style={{ whiteSpace: "nowrap" }}
            className="medium font__size--16 color__white"
            rel="noopener"
            href="#top"
          >
            <img src="./../images/logo.svg" alt="" />
          </a>

          <div
            className="collapse navbar-collapse wrapper__navbar-menu ml-lg-5"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav menu__center">
              <li className="nav-item">
                <a
                  style={{ whiteSpace: "nowrap" }}
                  className="hoverable nav-link medium font__size--16 color__white opacity__7 hover"
                  rel="noopener"
                  href="#who-we-are"
                >
                  Who We Are
                </a>
              </li>
              <li className="nav-item">
                <a
                  style={{ whiteSpace: "nowrap" }}
                  className="hoverable nav-link medium font__size--16 color__white opacity__7 hover"
                  rel="noopener"
                  href="#what-we-do"
                >
                  What We Do
                </a>
              </li>
              <li className="nav-item">
                <a
                  style={{ whiteSpace: "nowrap" }}
                  className="hoverable nav-link medium font__size--16 color__white opacity__7 hover"
                  rel="noopener"
                  href="#how-we-work"
                >
                  How We Work
                </a>
              </li>
            </ul>
          </div>

          <div
            onClick={() => setToogleMenu(!toogleMenu)}
            className="nav__button d-xl-none position-relative ml-xl-auto flex-shrink-0"
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <a
            style={{ whiteSpace: "nowrap" }}
            rel="noopener"
            href="#how-to-contact"
            className="d-none d-xl-block medium font__size--16 text__16-1024 btn btn__outlined--green color__green no__opacity shadow ml-auto"
          >
            Get In Touch
          </a>
        </div>
      </nav>
    </Fragment>
  );
};

export default Navbar;
