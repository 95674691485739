import React, { useState, Fragment, useRef } from "react";
import { NavLink } from "react-router-dom";
import Footer from "../../component/Other/Footer";
import Navbar from "../../component/Other/Navbar";
import SecContact from "../../component/Page/SecContact";
import SliderTestimonial from "../../component/Slider/SliderTestimonial";

const OurMission = (props) => {
  return (
    <Fragment>
      <div className="overflow-hidden">
        <section id="what-we-do" className="position-relative bg__black">
          <div style={{ paddingTop: "40px" }} className="container">
            <div className="row mb-5">
              <div
                style={{
                  opacity: "80%",
                  background: "url(./../images/dg/dot.png)",
                  backgroundSize: "300px 70px",
                  backgroundPosition: "60% bottom",
                  backgroundRepeat: "no-repeat",
                }}
                className="position-relative col-md-6 mb-4 text-center text-md-left my-md-auto"
              >
                <div
                  style={{
                    position: "absolute",
                    backgroundColor: "#03C49B",
                    width: 475,
                    height: 70,
                    maxWidth: 475,
                    left: -180,
                    top: -40,
                    opacity: 0.8,
                    zIndex: 1,
                  }}
                  className="my-lg-auto col-lg-7 text-right text-lg-left mb-5"
                >
                  &nbsp;
                </div>

                <img src="./../images/dg/sc (1).png" alt="" />
              </div>

              <div className="col-md-6 my-auto">
                <h2
                  style={{ margin: "32px 0 32px 0" }}
                  className="bold font__size--40 text__40-1024 text__40-md text__40-mm color__white syne position-relative z-2"
                >
                  Charting the Course for Digital Transformation
                </h2>

                <div
                  style={{ width: 311, height: 69 }}
                  className="mb-4 d-inline-block position-relative"
                >
                  <h3
                    style={{ bottom: "-21px", left: "50px" }}
                    className="bold font__size--24 text__40-1024 text__40-md text__40-mm color__white mb-0 syne position-relative z-2"
                  >
                    Our Mission
                  </h3>
                  <img
                    width={"311px"}
                    height={"69px"}
                    src="./../images/fhfghg.png"
                    className="images__line-text"
                    alt=""
                  />
                </div>

                <p style={{marginBottom:"72px"}} className="normal font__size--18 text__20-1024 color__white opacity__7">
                  At DigiTer, our mission is to spearhead the digital revolution
                  in the maritime industry. We're committed to delivering
                  innovative, reliable, and tailored solutions that empower our
                  partners to navigate the future with confidence.
                </p>
                <div
                  style={{ width: 311, height: 69 }}
                  className="mb-4 d-inline-block position-relative"
                >
                  <h3
                    style={{ bottom: "-21px", left: "50px" }}
                    className="bold font__size--24 text__40-1024 text__40-md text__40-mm color__white mb-0 syne position-relative z-2"
                  >
                    Our Vision
                  </h3>
                  <img
                    width={"311px"}
                    height={"69px"}
                    src="./../images/fhfghg.png"
                    className="images__line-text"
                    alt=""
                  />
                </div>

                <p className="normal font__size--18 text__20-1024 color__white opacity__7 mb-4">
                  Our deep understanding of the maritime sector's unique
                  challenges fuels our drive to innovate. But our vision extends
                  beyond the high seas. We're poised to broaden our horizons,
                  leveraging our expertise to transform other industries with
                  our professional software solutions.
                </p>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};

export default OurMission;
